import { useQuery } from '@tanstack/react-query'

import {
  CarouselPriorityList,
  CarouselPriorityListSchema,
  mappedCarouselPriorityList
} from '@Schemas/domain/carousel'

import contentAPI from '@Services/contentAPI'

const priorityListMapper = (rawList: CarouselPriorityList) => {
  const mapped = [] as mappedCarouselPriorityList
  let hasEvents = 0

  for (let i = 0; i <= rawList.length; i++) {
    if (rawList[i]?.carouselSlug === 'destaque') {
      continue
    }

    if (
      rawList[i]?.name.toLowerCase() === 'eventos brasil' ||
      rawList[i]?.name.toLowerCase() === 'eventos cidades'
    ) {
      if (!Boolean(hasEvents)) {
        mapped.push({
          ...rawList[i],
          name: 'Eventos',
          carouselSlug: [rawList[i]?.carouselSlug]
        })
        hasEvents = i
        continue
      }

      ;(mapped[hasEvents]?.carouselSlug as string[])?.push(
        rawList[i]?.carouselSlug
      )
      continue
    }

    mapped.push(rawList[i])
  }

  return mapped
}

const fetchData = async (cityId: string, partnershipId: string) => {
  const { data } = await contentAPI.get(
    `/v0/carousel/summary/${cityId}/partnership/${partnershipId}`
  )

  const mappedData = priorityListMapper(CarouselPriorityListSchema.parse(data))

  return mappedData
}

const useCarouselPriorityQuery = (
  cityId: string,
  partnershipId: string,
  options?: any
) =>
  useQuery<any>({
    queryKey: ['carousel-priority-list', cityId, partnershipId],
    queryFn: () => fetchData(cityId, partnershipId),
    ...options
  })

export default useCarouselPriorityQuery
