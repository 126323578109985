'use client'

import CarouselWrapper from 'src/modules/home/components/caroulsel-wrapper'
import Highlights from 'src/modules/home/components/highlights'
import Order from 'src/modules/home/components/order'

import InterstitialBanner from '@Components/ad-banner/interstitial-banner'
import UOLAdContainer from '@Components/ad-banner/uol-ads'
import AdBanners from '@Components/ad-banners'

const HomePage = () => {
  return (
    <main className="mkt__home" data-midia="ads_midia_page-home">
      <InterstitialBanner />
      <UOLAdContainer id="div-gpt-ad-background" className="h-0" />
      <div className="mkt__content min-h-[300px] pb-8">
        <Highlights />
        <div className="mx-auto my-0 max-w-screen-xl">
          <Order />
          <CarouselWrapper />
        </div>
        <AdBanners className="mx-auto my-0 flex max-w-screen-xl items-start" />
      </div>
    </main>
  )
}

export default HomePage
