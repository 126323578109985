import NextLink, { LinkProps as NextLinkProps } from 'next/link'

import { isExternalUrl } from '@Shared/helpers/string-operator'
import urlBuilder from '@Shared/helpers/url'

type LinkProps = NextLinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    openExternalInSameTab?: boolean
  }

const Link = ({
  href = '',
  as,
  children,
  onClick,
  openExternalInSameTab,
  ...rest
}: LinkProps) => {
  return children && isExternalUrl(href) ? (
    <a
      href={href}
      onClick={onClick}
      {...rest}
      target={openExternalInSameTab ? '_self' : '_blank'}
      rel="noreferrer noopener"
    >
      {children}
    </a>
  ) : href.startsWith('#') ? (
    <a href={urlBuilder(href)} onClick={onClick} {...rest}>
      {children}
    </a>
  ) : (
    <NextLink
      href={urlBuilder(href)}
      as={as}
      passHref
      prefetch={Boolean(rest.prefetch)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </NextLink>
  )
}

export default Link
