import { isExternalUrl } from '../string-operator'

export const urlBuilder = (url: string) => {
  if (typeof window === 'undefined') {
    return url
  }

  const urlWithoutSlash = url.startsWith('/') ? url.replace('/', '') : url

  const baseURL =
    document?.location?.hostname === 'localhost'
      ? location.origin
      : process.env.BASE_URL

  const newURL = new URL(
    isExternalUrl(url) ? url : `${baseURL}/${urlWithoutSlash}`
  )
  const params = new URLSearchParams(window.location.search)

  for (const [name, value] of params) {
    if (name === 'q') {
      break
    }

    if (!newURL.searchParams.has(name)) {
      newURL.searchParams.append(name, value)
    }
  }

  return newURL.href
}

export default urlBuilder
