import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { OrderSchema } from '@Schemas/domain/order'

import ticketingAPI from '@Services/ticketingAPI'

const OrdersSchema = z.array(OrderSchema)

const fetchUserOrders = async (userId: string) => {
  const response = await ticketingAPI.get(`users/${userId}/orders`)
  const { data } = response
  return data
}

const useNextOrderQuery = (userId, options?: any) =>
  useQuery({
    queryKey: ['nextOrder', userId],
    queryFn: () => fetchUserOrders(userId),
    retry: false,
    ...options
  })

export default useNextOrderQuery
